import React, { useEffect, useState, useMemo } from "react";
import style from "./Performance.module.css";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../Url";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import ActiveCalendar from "../../assets/activeCalendar.png";
import ActiveWorkOut from "../../assets/ActiveWeightlifting-sport-exercise-gym (1).png";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement, 
  LineElement,  
} from "chart.js";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement, // Import PointElement for Line charts
  LineElement,  
);

const Performance = () => {
  const location = useLocation();
  const userid = location.state?.userID;
  const orgid = location.state?.orgID;
  const clubId= location.state?.clubID

  const key = JSON.parse(localStorage.getItem("access-token"));

  const [mainData, setMainData] = useState({});
  const [roundGraphData,setRoundGraphData]=useState([])
  const [learningPaceData,setLearningPaceData]=useState({})
  const [learningLevelData,setLearningLevelData]=useState({})
  const [moduleList,setModuleList]=useState([]);
  const [learningLevelModID,setLearningLevelModID]=useState("");
  const [learningPaceModID,setLearningPaceModID]=useState("");
  const [moduleProgress,setModuleProgress]=useState(0)
  const [loading,setLoading]=useState(true)
  const [todaysDailyScore,setTodaysDailyScore]=useState(""); 
  const [todaysWeeklyScore,setTodaysWeeklyScore]=useState("");  
  const [dateForLP, setDateForLP] = useState("weekly");

  const [selectedModule, setSelectedModule] = useState(0);
  const [dailyDrillData, setDailyDrillData] = useState([]);
  const [weeklyWorkoutData, setWeeklyWorkoutData] = useState([]);
  const [dateForDD, setDateForDD] = useState("week");
  const [dateForWW, setDateForWW] = useState("week");
  const [categoryList,setCategoryList]=useState([]);
  const [selectedCategoryID,setSelectedCategoryID]=useState("")
  const [showContent,SetShowContent]=useState(false)
  const [achievementDetails,setAchievementDetails] = useState([]);

  const handleDDDate = (date) => {
    setDateForDD(date);
    dailyDrillGraph(date);
  };

  const handleWWDate = (date) => {
    setDateForWW(date);
    weeklyWorkoutGraph(date);
  };
  const handleLPDate =(date)=>{
    setDateForLP(date)
   
  }

  const getColorBasedOnPercentage = (percentage) => {
    if (percentage > 75) return "#03CD09";
    if (percentage > 50) return "#FFCB34";
    if (percentage > 25) return "#FF8800";
    return "#FF2443";
  };

  


 

  const dailyLineLabels = dailyDrillData.map((item) =>
    item["x-axis"].substring(8, 10)
  ); // Extracts DD from YYYY-MM-DD
  const dailyLineScores = dailyDrillData.map((item) => item["y-axis"]);
  const ddCorrectScores = dailyDrillData.map((item) => item["correct"]); // Array of correct scores
  const ddTimeInSeconds = dailyDrillData.map((item) => item["time_sec"]);

  const lineChartData = {
    labels: dailyLineLabels,
    datasets: [
      {
        label: "Today Scores",
        data: dailyLineScores,
        borderColor: "#595BDB",
        backgroundColor: "rgba(89, 91, 219, 0.2)",
        fill: true,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: "#595BDB",
        pointBorderColor: "#fff",
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };

  const weeklyLineLabels = weeklyWorkoutData.map((item) =>
    item["x-axis"].substring(8, 10)
  ); // Extracts DD from YYYY-MM-DD
  const weeklyLineScores = weeklyWorkoutData.map((item) => item["y-axis"]);
  const wwCorrectScores = weeklyWorkoutData.map((item) => item["correct"]); // Array of correct scores
  const wwTimeInSeconds = weeklyWorkoutData.map((item) => item["time_sec"]);

  const weeklyLineChart = {
    labels: weeklyLineLabels,
    datasets: [
      {
        label: "Weekly Scores",
        data: weeklyLineScores,
        borderColor: "#29bcd6",
        backgroundColor: "#7adef0",
        fill: true,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: "#29bcd6",
        pointBorderColor: "#fff",
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };

  const learningPaceLabels = learningPaceData?.data?.map(item => dayjs(item.period).format('DD')); // Extracting periods for x-axis
  const topicCounts =learningPaceData?.data?.map(item => item.topic_count); // Extracting topic counts for y-axis
  
  const learningPaceChartData = {
      labels: learningPaceLabels,
      datasets: [
          {
              label: 'Topic Count',
              data: topicCounts,
              backgroundColor: '#FF8800', 
              barThickness: 20, 
              borderRadius: 10, 
          }
      ]
  };
  
  const learningPaceOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    return `Topic ${context.dataIndex + 1}: ${context.raw}`;
                },
            },
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: `Day (${dayjs().format('MMM')})`,
            },
            grid: {
                display: false,
            },
            beginAtZero: false,
            ticks: {
                stepSize: 1,
            },
        },
        y: {
            title: {
                display: true,
                text: "Total no.of Topics",
            },
            beginAtZero: true,
            max: learningPaceData.module_topics_count, 
            ticks: {
                stepSize: 1, 
                callback: function(value) {
                    return value; 
                },
            },
        },
    },
  };
  
  const learningLevelGraphData = {
    labels: [...Array(learningLevelData?.module_topics_count).keys()], 
    datasets: [
      {
        label: 'Baseline Score',
        data: learningLevelData?.data?.map((topicArray) => {
          const baselineData = topicArray.find(topic => topic.score_level === 'baseline');
          return baselineData ? parseFloat(baselineData.score) : null;
        }), 
        backgroundColor: '#595BDB', 
        barThickness: 20,
        borderRadius: 10, 
      },
      {
        label: 'Current Score',
        data: learningLevelData?.data?.map((topicArray) => {
          const currentData = topicArray.find(topic => topic.score_level === 'current');
          return currentData ? parseFloat(currentData.score) : null;
        }), 
        backgroundColor: '#FFBE00', 
        barThickness: 20,
        borderRadius: 10, 
      },
    ],
  };
  
  const learningLevelOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}%`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Topics',
        },
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
          callback: function (value) {
            return value + 1; 
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Score (%)',
        },
        beginAtZero: true,
        max: 100, 
        ticks: {
          stepSize: 20,
          callback: function (value) {
            return value + '%'; 
          },
        },
      },
    },
  };
  
  async function categoryGet() {
    await fetch(`${baseUrl}/performance/categories-list`, {
      method: "POST",
      body: JSON.stringify({ 
        org_id:orgid,
        club_id:clubId
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
        setCategoryList(data.data)
        }
        else{
          setCategoryList([])
        }
      });
  }

  async function mainAnalytics() {
    await fetch(`${baseUrl}/performance/analytics/main`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: userid,
        org_id:orgid,
        category_id:selectedCategoryID
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setMainData(data.data[0])
         const initialModuleId=data.data[0]?.Modules[0].module_id
         topicAnalytics(initialModuleId)
         setLearningPaceModID(initialModuleId)
         setLearningLevelModID(initialModuleId)
         setModuleList(data.data[0]?.Modules)
         setTimeout(() => {
          SetShowContent(true)
         }, 2000);
        }
        else{
          setMainData({})
          SetShowContent(true)
        }
      });
  }

  async function topicAnalytics(modID) {
    await fetch(`${baseUrl}/performance/module-topic-summary`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: userid,
        module_id:modID
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setRoundGraphData(data.data)
         setModuleProgress(data.module_progress)
        }
        else{
          setRoundGraphData([])
          setModuleProgress(0)
        }
      });
  }
  async function learningPace(modID,date) {
    await fetch(`${baseUrl}/performance/topic-count`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: userid,
        module_id:modID,
        filter: date
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setLearningPaceData(data)
        }
        else{
          setLearningPaceData({})
        }
      });
  }
  async function learningLevel(modID,) {
    await fetch(`${baseUrl}/performance/learning-level`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: userid,
        module_id:modID,
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setLearningLevelData(data)
        }
        else{
          setLearningLevelData()
        }
      });
  }
  async function dailyDrillGraph(type) {
    await fetch(`${baseUrl}/analytics/scores/daily`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userid,
        filter_type: type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setDailyDrillData(data.data);
          setTodaysDailyScore(data.todays_score)
        } else {
          setDailyDrillData([]);
        }
      });
  }
  async function weeklyWorkoutGraph(type) {
    await fetch(`${baseUrl}/analytics/scores/weekly`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userid,
        filter_type: type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setWeeklyWorkoutData(data.data);
          setTodaysWeeklyScore(data.todays_score)
        } else {
          setWeeklyWorkoutData([]);
        }
      });
  }
  async function badgeGet() {
    const body = {
      user_id: userid,
    };

    await fetch(`${baseUrl}/user_badges`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const topicAndModule = data.data?.Topic_and_module?.filter(item => item.status === true) || [];
          const dailyDrill = data.data?.Daily_Drill?.filter(item => item.status === true) || [];
          const weeklyWorkout = data.data?.Weekly_Workout?.filter(item => item.status === true) || [];

          const sortedData = [...topicAndModule, ...dailyDrill, ...weeklyWorkout];
          setAchievementDetails(sortedData)
          
          
        }
      });
  }

  useEffect(() => {
    if (selectedCategoryID) {
      mainAnalytics();
      dailyDrillGraph("week");
      weeklyWorkoutGraph("week");
      badgeGet()
    }
  }, [selectedCategoryID]);

  useEffect(()=>{
    categoryGet();
  },[location.state])

  useEffect(()=>{
    if(learningPaceModID){
      learningPace(learningPaceModID,dateForLP)
    }
   
  },[learningPaceModID,dateForLP])

  useEffect(()=>{
    if(learningLevelModID){
      learningLevel(learningLevelModID)
    }
  },[learningLevelModID])

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Performance</p>
        </div>
      </div>
      <div className={style.secondHead}>

      </div><div className={style.secondHead}>
          <div className={style.select_section}>
            <div className={style.Org_Select}>
              <FormControl sx={{ width: "350px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Course
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Course"
                  value={selectedCategoryID}
                  onChange={(e)=>setSelectedCategoryID(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, 
                      },
                    },
                  }}
                >
                  {categoryList&&categoryList.length>0?categoryList.map((cat)=>{
                    return(
                      <MenuItem key={cat.category_id} value={cat.category_id}>{cat.name}</MenuItem>
                    )
                  }):<MenuItem disabled>No Course available</MenuItem>}
                  
                    
                 
                </Select>
              </FormControl>
            </div>
          </div>
      </div>
      {showContent?
      <div className={style.performance}>
          
            <div className={style.moduleSection}>
            <div className={style.moduleHeder}>
                <p>Modules</p>
            </div>
            <div className={style.numberBarSection}>
                <div className={style.numberBar}>
                  {mainData.Modules&&mainData.Modules.length>0&&mainData.Modules.map((module, index) => (
                    <div
                      key={module.module_id}
                      className={
                        selectedModule === index
                          ? style.selectedNumber
                          : style.number
                      }
                      onClick={() => {
                          setSelectedModule(index)
                          topicAnalytics(module.module_id)
                        }}
                    >
                      {index + 1}
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.moduleSubHeder}>
              {mainData.Modules?.length > 0 && mainData.Modules[selectedModule] ? (
                    <p>
                      Module progress:{" "}
                      <span>{mainData.Modules[selectedModule]?.module_name}</span>
                    </p>
                  ) : (
                    <p>Module progress: <span>No Module Selected</span></p>
                  )}
            </div>
            <div className={style.progressBarSection}>
            <div
              className={style.progressBar}
              style={{
                
                backgroundColor: "#8E88903D",
                borderRadius: "2rem",
              }}
            >
              <div
                style={{
                  width: `${moduleProgress}%`,
                  backgroundColor: "#595BDB",
                  height: "12px",
                  transition: "width 0.5s ease-in-out",
                  borderRadius: "1rem",
                }}
              ></div>
            </div>
            <div className={style.progressBarCount}>
            <p>{Math.round(moduleProgress)}%</p>
            </div>
          </div>
          <div className={style.roundGraphSection}>
              {roundGraphData.length>0&&roundGraphData.map((data,i)=>{
                const color = getColorBasedOnPercentage(data.percentage_correct);
                const chartData = {
                  labels: ["Achieved", "Remaining"],
                  datasets: [
                    {
                      data: [data.percentage_correct, 100 - data.percentage_correct], 
                      backgroundColor: [color, "#EAEAEA"],   
                      borderWidth: 0,
                    },
                  ],
                };
      
                const options = {
                  cutout: '80%', 
                  plugins: {
                    legend: {
                      display: false, 
                    },
                    tooltip: {
                      enabled: false, 
                    },
                  },
                };
                return(
                <div key={i} className={style.roundGraphCard}>
                <div className={style.roundGraphCardHeder}>
                  <p>Topic {i+1}</p>
                </div>
                <div className={style.roundGraph}>
                <Doughnut data={chartData} options={options} />
                <div className={style.roundGraphData}>
                  <p>{data.correct_answers}</p>
                </div>
                </div>
  
                <div className={style.roundGraphCardBottom}>
                  <p>{Math.round(data.percentage_correct)}%</p>
                </div>
                </div>
                )
              })}
            </div>
        </div>

        <div className={style.learningSection}>
        <div className={style.learningSectionLeft}>
          <div className={style.learningHeader}>
            <p>Learning Pace</p>
          </div>
          <div className={style.learningSubHeader}  >
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForLP==="weekly"?style.selectedSubHeaderCard:""}`}
            onClick={() => handleLPDate("weekly")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForLP==="monthly"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleLPDate("monthly")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForLP==="yearly"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleLPDate("yearly")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
           <select
           value={learningPaceModID}
           onChange={(e)=>setLearningPaceModID(e.target.value)}
           >
            {moduleList&&moduleList.length>0&&moduleList.map((mod)=>(
              <option key={mod.module_id} value={mod.module_id}>{mod.module_name}</option>
            ))}
           </select>
           </div>
          </div>
          <div className={style.barGraph}>
          <Bar data={learningPaceChartData} options={learningPaceOptions} />
          </div>
        </div>
        <div className={style.learningSectionRight}>
        <div className={style.learningHeader}>
            <p>Learning Level</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.levelHeader}>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Current Score</h4>
            <p>(The mark scored in the last attempt)
            </p>
          </div>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Baseline score</h4>
            <p>(The mark scored in the first attempt)
            </p>
          </div>
          </div>
           <div className={style.learningSubHeaderRight}>
           <select
           value={learningLevelModID}
           onChange={(e)=>setLearningLevelModID(e.target.value)}
           >
            {moduleList&&moduleList.length>0&&moduleList.map((mod)=>(
              <option key={mod.module_id} value={mod.module_id}>{mod.module_name}</option>
            ))}
           </select>
           </div>
          </div>
          <div className={style.barGraph}>
          <Bar
            data={learningLevelGraphData}
            options={learningLevelOptions}
          />
    
          </div>
        </div>
        </div>
        <div className={style.assessmentSection}>
        <div className={style.assessmentSectionLeft}>
        <div className={style.learningHeader}>
            <p>Daily drill</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForDD==="week"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("week")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForDD==="month"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("month")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForDD==="year"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("year")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore}>
              <p>Today’s <br/>
              Score</p>
              <h4>{todaysDailyScore}</h4>
            </div>
           </div>
          </div>
          <div className={style.lineGraph}>
          <Line
              data={lineChartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const index = context.dataIndex; 
                        const percentage = dailyLineScores[index].toFixed(2); 
                        const date = dailyDrillData[index]["x-axis"]; 
                       
                        return `You scored ${percentage}% on ${date}`;                      },
                    },
                  },
                },
                scales: {
                  x: {
                    type: 'category', 
                    title: {
                      display: true,
                      text: `Day `, 
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: false,
                    offset:true,
                    ticks: {
                      callback: function(value, index) {
                        return dailyLineLabels[index]; 
                      },
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />

          </div>
        </div>
        <div className={style.assessmentSectionRight}>
        <div className={style.learningHeader}>
            <p>Weekly Workout</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForWW==="week"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("week")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForWW==="month"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("month")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForWW==="year"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("year")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore} style={{backgroundColor:"#00DFC7"}}>
              <p>Week's <br/>
              Score</p>
              <h4>{todaysWeeklyScore}</h4>
            </div>
           </div>
          </div>

          <div className={style.lineGraph}>
          <Line
              data={weeklyLineChart}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const index = context.dataIndex;
                        const percentage = weeklyLineScores[index].toFixed(2); 
                        const date = weeklyWorkoutData[index]["x-axis"]; 
                       
                        return `You scored ${percentage}% on ${date}`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: `Day `,
                    },
                    grid: {
                      display: false,
                    },
                    offset:true,
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        </div>
        {achievementDetails&&achievementDetails.length>0? 
              <>
          <div className={style.achievementHeader}>
              <p>Badges</p>
            </div>
            <div className={style.achievementSection}>
            <div className={style.achievementGrid}>
                {achievementDetails&&achievementDetails.length>0&&achievementDetails.map((data)=>(
                   <div key={data.badge_id} className={style.achievementCard}
                  
                   >
                   <div className={style.achievementCardImage}>
                     <img src={data.active_img} alt="img"/>
                   </div>
                   <div className={style.achievementCardText}>
                     <p>{data.name} </p>
                   </div>
                   </div>
                ))}
               
              </div>
              <div className={style.viewText}>
                <p>View all</p>
              </div>
              </div>
              </>
              :""}
      </div>
      :""}
    </div>
  );
};

export default Performance;
